/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three'
import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { GLTF } from 'three-stdlib'

type GLTFResult = GLTF & {
  nodes: {
    Cylinder012_1: THREE.Mesh
    Cylinder012_2: THREE.Mesh
    Cylinder012_3: THREE.Mesh
    Cylinder012_4: THREE.Mesh
    Cylinder012_5: THREE.Mesh
    Cylinder006: THREE.Mesh
    Cylinder006_1: THREE.Mesh
    Cylinder005: THREE.Mesh
    Cylinder004: THREE.Mesh
    Cylinder003_1: THREE.Mesh
    Cylinder003_2: THREE.Mesh
    Cylinder: THREE.Mesh
  }
  materials: {
    Material: THREE.MeshStandardMaterial
    ['Material.006']: THREE.MeshStandardMaterial
    ['Material.007']: THREE.MeshStandardMaterial
    ['Material.008']: THREE.MeshStandardMaterial
    ['Material.009']: THREE.MeshStandardMaterial
    ['Material.003']: THREE.MeshStandardMaterial
    ['Material.004']: THREE.MeshStandardMaterial
    ['Material.002']: THREE.MeshStandardMaterial
    ['Material.001']: THREE.MeshStandardMaterial
  }
}

export default function Model({ ...props }: JSX.IntrinsicElements['group']) {

  const group = useRef<THREE.Group>(null!)

  const { nodes, materials } = useGLTF('/SpaceShuttel.glb') as GLTFResult
  
  return (
    <group ref={group} {...props} dispose={null}>
      <group position={[-2.11, 1.09, -1.49]} rotation={[-Math.PI, Math.PI / 4, -Math.PI]} scale={0.11}>
        <mesh geometry={nodes.Cylinder012_1.geometry} material={materials.Material} />
        <mesh geometry={nodes.Cylinder012_2.geometry} material={materials['Material.006']} material-color={'pink'} />
        <mesh geometry={nodes.Cylinder012_3.geometry} material={materials['Material.007']} />
        <mesh geometry={nodes.Cylinder012_4.geometry} material={materials['Material.008']} />
        <mesh geometry={nodes.Cylinder012_5.geometry} material={materials['Material.009']} />
      </group>
      <group position={[-1.35, 1.79, -0.83]} rotation={[0, Math.PI / 2, 0]} scale={[0.15, 1.49, 0.15]}>
        <mesh geometry={nodes.Cylinder006.geometry} material={materials['Material.003']} />
        <mesh geometry={nodes.Cylinder006_1.geometry} material={materials['Material.004']} />
      </group>
      <mesh geometry={nodes.Cylinder005.geometry} material={materials['Material.004']} position={[-1.35, 1, -1.33]} rotation={[Math.PI / 2, Math.PI / 2, 0]} scale={[0.02, 0.4, 0.02]} />
      <mesh geometry={nodes.Cylinder004.geometry} material={materials['Material.004']} position={[-1.35, 3.11, -1.33]} rotation={[Math.PI / 2, Math.PI / 2, 0]} scale={[0.06, 0.39, 0.06]} />
      <group position={[-1.35, 1.79, -1.83]} rotation={[0, Math.PI / 2, 0]} scale={[0.15, 1.49, 0.15]}>
        <mesh geometry={nodes.Cylinder003_1.geometry} material={materials['Material.002']} />
        <mesh geometry={nodes.Cylinder003_2.geometry} material={materials['Material.004']} />
      </group>
      <mesh geometry={nodes.Cylinder.geometry} material={materials['Material.001']} position={[-1.35, 2.21, -1.33]} rotation={[0, Math.PI / 2, 0]} scale={[0.33, 1.35, 0.33]} />
    </group>
  )
}

useGLTF.preload('/SpaceShuttel.glb')
